import React from 'react'
import intl from 'react-intl-universal'
import Lightbox from 'react-simple-lightbox'
import { connect } from 'react-redux'
import { ReactComponent as SmallCheck } from '../assets/icons/check.svg'

const mapStateToProps = state => ({
  group_treshold: state.config.group_treshold || 10,
  lang: state.auth.lang
})

class ProductListItemComponent extends React.Component {
  constructor(props) {
    super(props)
    const { group_treshold, product, parent, childIndex } = props
    const qty = product.quantity
    parent.lines[childIndex] = this
    this.state = {
      decision: {},
      action: {},
      checked: {},
      expand: (qty >= group_treshold) ? false : true
    }
  }

  getActionRadio = (opt, key, action)=>{
    return (<label htmlFor={`actions_${key}_${opt[0]}`}
             key={`actions_${key}_${opt[0]}`}>
      <input type="radio"
             name={`actions_${key}`}
             id={`actions_${key}_${opt[0]}`}
             data-index={(''+key).split('.').slice(-1)[0]}
             onChange={this.actionChange}
             checked={action === (''+opt[0])}
             value={opt[0]}/>
      <div className="check">
        <div className="check-icon">
          <SmallCheck/>
        </div>
        {opt[1]}
      </div>
    </label>)
  }

  toggleExpand = ()=>{
    const { expand } = this.state
    this.setState({
      expand: !expand
    })
  }

  checkboxClick = (e) => {
    const { checked, expand } = this.state
    const { product, parent } = this.props
    let new_checked
    const $checkbox = e.currentTarget.children[0]
    const value = !$checkbox.classList.contains('checked')
    if(expand){
      const index = parseInt(e.currentTarget.dataset.index)
      new_checked = {
        ...checked,
        [index]: value
      }
    } else {
      new_checked = Array(product.quantity).fill(value)
    }
    this.setState({
      checked: new_checked,
    }, () => {
      parent.toggleConfirmButton()
    })
  }

  decisionChange = (e)=>{
    const { decision, action, expand } = this.state
    const { product, parent } = this.props
    let new_decision, new_action
    if(expand && product.quantity % 1 === 0){
      const index = parseInt(e.currentTarget.dataset.index)
      new_decision = {
        ...decision,
        [index]: e.currentTarget.value
      }
      new_action = {
        ...action,
        [index]: undefined
      }
    } else {
      if(product.quantity % 1 === 0) {
        new_decision = Array(product.quantity).fill(e.currentTarget.value)
        new_action = Array(product.quantity).fill(undefined)
      } else {
        new_decision = [e.currentTarget.value]
        new_action = [undefined]
      }
    }
    this.setState({
      decision: new_decision,
      action: new_action
    }, () => {
      parent.toggleConfirmButton()
    })
  }

  actionChange = (e)=>{
    const { action, expand } = this.state
    const { product, parent } = this.props
    let new_action
    if(expand && product.quantity % 1 === 0){
      const index = parseInt(e.currentTarget.dataset.index)
      new_action = {
        ...action,
        [index]: e.currentTarget.value
      }
    } else {
      if(product.quantity % 1 === 0) {
        new_action = Array(product.quantity).fill(e.currentTarget.value)
      } else {
        new_action = [e.currentTarget.value]
      }
    }
    this.setState({
      action: new_action
    }, () => {
      parent.toggleConfirmButton()
    })
  }

  renderItem(product, index){
    const { decision, action } = this.state
    let key = product.id || product.sale_order_line_id
    let item_decision = decision[0]
    let item_action = action[0]
    if(typeof index !== 'undefined'){
      key = product.id+'.'+index
      item_decision = decision[index]
      item_action = action[index]
    }
    const odoo = window.ODOO.url + (window.ODOO.port?':'+window.ODOO.port:'')+'/product/image?default_code='
    return(
      <div key={key}>
        <div className="product_leftcol">
          <Lightbox>
            <img src={odoo+product.default_code} alt="product"/>
          </Lightbox>
        </div>
        <div className="product_details">
          <h4>
            {product.name}
            {product.default_code &&
              <div className="default_code">[{product.default_code}]</div>
            }
          </h4>
          { product.quantity > 1 &&
            <div className="qty f_group">
              <label>{intl.get('Ilość')}:</label>
              <div>{product.quantity}</div>
            </div>
          }
          {(product.reason || product.integration_reason) &&
            <div className="request f_group">
              <label>{intl.get('Przyczyna')}:</label>
              <div>
              { product.reason || product.integration_reason || ''}
              </div>
            </div>
          }
          <div className="price f_group">
            <label>{intl.get('Cena jednostkowa')}:</label>
            <div>{product.price_unit}</div>
          </div>
          { product.product_note &&
            <div className="notes f_group full_width">
              <label>{intl.get('Notatki')}:</label>
              <div style={{'paddingLeft': '32px'}}>
                <span>{product.product_note}</span>
              </div>
            </div>
          }
          <div className="actions" data-decision={item_decision}>
            <div className="actions_0">
              {product.approve_options.map((opt)=>this.getActionRadio(opt, key, item_action))}
            </div>
            <div className="actions_1">
              {product.decline_options.map((opt)=>this.getActionRadio(opt, key, item_action))}
            </div>
          </div>
        </div>
        <div className="product_decision" data-line={key}>
          <label htmlFor={`decision_${key}_0`}>
            <input
              type="radio"
              name={`decision_${key}`}
              id={`decision_${key}_0`}
              data-index={index}
              onChange={this.decisionChange}
              checked={item_decision === '0'}
              value="0"/>
            <div className="check"></div>
            {intl.get('Zasadna')}
          </label>

          <label htmlFor={`decision_${key}_1`}>
            <input
              type="radio"
              name={`decision_${key}`}
              id={`decision_${key}_1`}
              data-index={index}
              onChange={this.decisionChange}
              checked={item_decision === '1'}
              value="1"/>
            <div className="check"></div>
            {intl.get('Niezasadna')}
          </label>
          <label htmlFor={`decision_${key}_2`}>
            <input
              type="radio"
              name={`decision_${key}`}
              id={`decision_${key}_2`}
              data-index={index}
              onChange={this.decisionChange}
              checked={item_decision === '2'}
              value="2"/>
            <div className="check"></div>
            {intl.get('Brak w paczce')}
          </label>
        </div>
      </div>
    )
  }

  render() {
    const { group_treshold, product } = this.props
    const { expand } = this.state
    const qty = product.quantity
    let className = `ramka produkt ${expand ? 'expanded' : ''}`
    return (
      <div className={className}>
        { (!expand || product.quantity % 1 !== 0) &&
          this.renderItem(product)
        }
        { expand && (product.quantity % 1 === 0) &&
          [...Array(product.quantity).keys()].map((k) => {
            return this.renderItem(
              {
                ...product,
                quantity: 1,
              }, k
            )
          })
        }
        {(qty >= group_treshold) && (qty % 1 === 0) && 
          <button className="expander" title={intl.get('Rozwiń')} onClick={this.toggleExpand}>• • •</button>
        }
      </div>
    )
  }
}

const ProductListItem = connect(mapStateToProps)(ProductListItemComponent)
export default ProductListItem
